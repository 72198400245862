import { LearningPathCourse } from "types/learning-path";
import { LearnerPathStatus } from "../learner-path-status";

type Props = { item: LearningPathCourse };

export const SeminarItem = ({ item }: Props) => (
  <li className="learner-path__item">
    <div>
      <span className="learner-path__item-chip learner-path__item-chip--id">{item.courseCode}</span>
      <span className="learner-path__item-name">{item.courseName}</span>
    </div>
    <LearnerPathStatus item={item} />
  </li>
);
