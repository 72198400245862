import cn from "classnames";

type Props = {
  grade: string;
  status: "passed" | "failed";
};

export const GradeChip = ({ grade, status }: Props) => (
  <span
    className={cn(
      "learner-path__item-chip learner-path__item-chip--grade",
      status === "failed" && "learner-path__item-chip--grade-failed",
    )}
  >
    <>
      {grade}
      <span>{status === "failed" ? "Failed" : "Passed"}</span>
    </>
  </span>
);
