import { LearningPathCourse } from "types/learning-path";
import { IonButton, IonIcon, IonPopover } from "@ionic/react";
import { informationCircleOutline } from "ionicons/icons";
import { LearnerPathStatus } from "../learner-path-status";

const CapstoneIcon = () => (
  <div>
    <IonButton
      id="capstone-tooltip-trigger"
      aria-label="Open the capstone tooltip"
      shape="round"
      size="small"
      data-testid="capstone-tooltip-trigger"
      onClick={(e) => e.stopPropagation()}
    >
      <IonIcon slot="icon-only" icon={informationCircleOutline} />
    </IonButton>
    <IonPopover
      className="capstone-tooltip"
      trigger="capstone-tooltip-trigger"
      triggerAction="click"
      side="bottom"
      alignment="start"
    >
      <p>Course cannot be taken simultaneously with other courses</p>
    </IonPopover>
  </div>
);

type Props = {
  item: LearningPathCourse;
  onClick?: () => void;
  isMulti: boolean;
};

export const LearnerPathItem = ({ item, onClick, isMulti }: Props) => (
  <li className="learner-path__item">
    <button aria-label={`Open course description for ${item.courseName}`} onClick={onClick}>
      <div className="learner-path__item-chip-container">
        <span className="learner-path__item-chip learner-path__item-chip--id">{item.courseCode}</span>
        <span className="learner-path__item-name">{item.courseName}</span>
        {item.enrollmentBehaviour === "Standalone" && isMulti && <CapstoneIcon />}
      </div>
      <LearnerPathStatus item={item} />
    </button>
  </li>
);
