export const formatAmount = (balance: number) => (balance < 0 ? `-$${Math.abs(balance)}` : `$${balance}`);

export const getEarlyInvoiceDate = (nextBillDate?: string | null) => {
  if (!nextBillDate) return null;

  const earlyInvoiceDate = new Date(nextBillDate);
  const timezoneOffset = new Date().getTimezoneOffset();
  earlyInvoiceDate.setHours(earlyInvoiceDate.getHours() - timezoneOffset / 60);

  return earlyInvoiceDate;
};
