import { formatDate } from "utils/format-date.utils";
import { formatAmount, getEarlyInvoiceDate } from "./utils";

export type Props = {
  balance: number;
  nextBillDate?: string | null;
};

export const WalletBalance = ({ balance, nextBillDate }: Props) => {
  const earlyInvoiceDate = getEarlyInvoiceDate(nextBillDate);
  const startDateFormatted = earlyInvoiceDate ? formatDate(earlyInvoiceDate) : null;

  return (
    <div className="payment-info__row">
      <div className="payment-info__col payment-info__balance" data-testid="payment-wallet-balance">
        <span>Wallet balance</span>
        <span data-testid="payment-wallet-balance-amount">{formatAmount(balance)}</span>
      </div>
      <div className="payment-info__col payment-info__date" data-testid="payment-next-invoice-date">
        {startDateFormatted && (
          <span data-testid="payment-next-invoice-date-container">
            <span>Next invoice available on</span>
            <span>{startDateFormatted}</span>
          </span>
        )}
      </div>
    </div>
  );
};
