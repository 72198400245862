import { IonButton, IonIcon, IonPopover } from "@ionic/react";
import { LearningPathCourse } from "types/learning-path";
import { informationCircleOutline } from "ionicons/icons";
import { NEXFORD_SEMINARS_FAQ } from "constants/external-routes";
import { SeminarItem } from "./seminar-item";

type Props = {
  list?: LearningPathCourse[];
};

export const SeminarList = ({ list }: Props) => {
  if (!list?.length) return null;

  return (
    <div className="learner-path__list-wrapper">
      <div className="learner-path__list-title">
        <h2>Your Seminars</h2>
        <IonButton id="seminars-tooltip-trigger" aria-label="Open the seminars tooltip" shape="round" size="small">
          <IonIcon slot="icon-only" icon={informationCircleOutline}></IonIcon>
        </IonButton>
        <IonPopover
          className="seminars-tooltip"
          trigger="seminars-tooltip-trigger"
          triggerAction="click"
          side="bottom"
          alignment="start"
        >
          <p>
            You are required to take seminars when they are available.{" "}
            <a href={NEXFORD_SEMINARS_FAQ} target="_blank">
              More information
            </a>
          </p>
        </IonPopover>
      </div>
      <ul data-testid="learner-path-seminars">
        {list.map((item) => (
          <SeminarItem key={item.courseCode} item={item} />
        ))}
      </ul>
    </div>
  );
};
