import { LearningPathCourse } from "types/learning-path";
import { LearnerPathItem } from "./learner-path-item";

type Props = {
  list?: LearningPathCourse[];
  onItemClick: (item: LearningPathCourse) => void;
};

export const LearnerPathListContent = ({ list, onItemClick }: Props) => {
  if (!list?.length) return null;

  const notSeminarList = list.filter((item) => !item.isSeminar);

  return (
    <div className="learner-path__list-wrapper">
      <div className="learner-path__list-title">
        <h2>Your Learning Path</h2>
      </div>
      <ul data-testid="learner-path-list">
        {notSeminarList.map((item) => (
          <LearnerPathItem
            key={item.courseCode}
            item={item}
            onClick={() => onItemClick(item)}
            isMulti={notSeminarList.length > 1}
          />
        ))}
      </ul>
    </div>
  );
};
