import { IonIcon } from "@ionic/react";
import { swapHorizontalOutline } from "ionicons/icons";
import { CourseEnrollmentStatus, CourseEnrollmentSubStatus, LearningPathCourse } from "types/learning-path";
import { getEnrollmentStatusLabel } from "utils/program-utils";
import { formatShortDate } from "utils/format-date.utils";
import { GradeChip } from "./grade-chip";

const ExternalTransfer = () => (
  <div className="learner-path__item-transfer">
    <IonIcon
      className="learner-path__item-transfer__icon"
      icon={swapHorizontalOutline}
      aria-label="Course is an External Transfer"
      title="External transfer"
    />
  </div>
);

const EnrollmentStatusChip = ({ status }: { status: string }) => (
  <span className="learner-path__item-chip learner-path__item-chip--status">{getEnrollmentStatusLabel(status)}</span>
);

const SeminarAvailability = ({
  nextAvailableAt,
  substatus,
  grade,
}: Pick<LearningPathCourse, "nextAvailableAt" | "substatus" | "grade">) => (
  <div className="learner-path__item-chips">
    {substatus === CourseEnrollmentSubStatus.Failed && <GradeChip grade={grade || ""} status="failed" />}
    <span className="learner-path__item-chip learner-path__item-chip--availability">
      {nextAvailableAt ? (
        <>
          Available:
          <span>{formatShortDate(nextAvailableAt)}</span>
        </>
      ) : (
        <>Coming Soon</>
      )}
    </span>
  </div>
);

type Props = {
  item: LearningPathCourse;
};

export const LearnerPathStatus = ({ item }: Props) => {
  if (item.isExternalTransfer) {
    return <ExternalTransfer />;
  }

  if (item.status === "completed" && item.substatus === "passed") {
    return <GradeChip grade={item.grade || ""} status="passed" />;
  }

  if (item.status === CourseEnrollmentStatus.Enrolled && item.substatus) {
    return <EnrollmentStatusChip status={item.substatus} />;
  }

  if (item.isSeminar) {
    return <SeminarAvailability grade={item.grade} nextAvailableAt={item.nextAvailableAt} substatus={item.substatus} />;
  }

  if (item.substatus === CourseEnrollmentSubStatus.Failed) {
    return <GradeChip grade={item.grade || ""} status="failed" />;
  }

  return <></>;
};
