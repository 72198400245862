import { formatDate } from "utils/format-date.utils";
import { TuitionInvoice } from "types/wallet";
import { formatAmount } from "./utils";

type Props = {
  totalAmountDue: number;
  invoices?: TuitionInvoice[];
};

export const WalletDebt = ({ totalAmountDue, invoices }: Props) => {
  const dueDateFormatted = invoices?.length ? formatDate(invoices[0].dueDate) : null;

  return (
    <div data-testid="payment-balance-debt" className="payment-info__row">
      <div className="payment-info__col payment-info__balance" data-testid="payment-balance-due">
        <span>Total amount due</span>
        <span data-testid="payment-balance-due-amount">{formatAmount(totalAmountDue)}</span>
      </div>
      <div className="payment-info__col payment-info__date" data-testid="payment-due-date">
        {dueDateFormatted && (
          <span data-testid="payment-due-date-container">
            <span>Payment due on</span>
            <span>{dueDateFormatted}</span>
          </span>
        )}
      </div>
    </div>
  );
};
