import CardPanel from "components/atom/card-panel";

import "./payment-info.scss";
import { TuitionRequest } from "types/wallet";
import { PaymentBalance } from "./payment-balance/payment-balance";
import { AmountForm, AmountFormProps } from "./amount-form/amount-form";
import { PaymentAlert } from "./payment-alert/payment-alert";

type Props = AmountFormProps & {
  tuitionData: TuitionRequest;
};

/**
 * Form component to set the amount to be paid
 */
const PaymentInfo = ({ tuitionData, ...props }: Props) => {
  const { providersList, providersRequestLoading } = props;

  return (
    <section>
      <CardPanel className="payment-info__wrapper">
        <PaymentBalance tuitionData={tuitionData} />
      </CardPanel>

      <CardPanel className="payment-info__wrapper payment-info__form" testId="payment-info__form">
        <AmountForm {...props} />
      </CardPanel>

      <PaymentAlert providersRequestLoading={providersRequestLoading} providersList={providersList} />
    </section>
  );
};

export default PaymentInfo;
