import { IonInput } from "@ionic/react";
import { Control, Controller, ControllerProps, FieldValues, UseControllerProps } from "react-hook-form";

type Props<T extends FieldValues> = {
  onChange: () => void;
  disabled: boolean;
} & UseControllerProps<T>;

export const AmountInput = <T extends FieldValues>({ onChange, disabled, control, name }: Props<T>) => (
  <Controller
    control={control}
    name={name}
    disabled={disabled}
    render={({ field, fieldState }) => (
      <div className="payment-info__field-item" data-testid="amount-to-pay-textbox">
        <span>$</span>
        <IonInput
          data-testid="amount-to-pay-input"
          label="Enter amount to pay (USD)"
          labelPlacement="stacked"
          placeholder="ex. $150.00"
          fill="outline"
          type="number"
          step="any"
          value={field.value || null}
          errorText={fieldState.error?.message}
          className={fieldState.error ? "ion-touched ion-invalid" : ""}
          onIonInput={(e) => {
            onChange?.();
            field.onChange(e);
          }}
          onIonBlur={field.onBlur}
          disabled={disabled}
        />
      </div>
    )}
  />
);
