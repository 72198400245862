import React from "react";
import { IonButton } from "@ionic/react";

import "./button-secondary.scss";

export type SecondaryButtonProps = {
  children: React.ReactNode;
  // Should be applied to provide an aria-label when btnText is uninituitive
  // eg btnText: 'X' should have ariaText: 'Close Modal'
  ariaText?: string;
} & React.ComponentProps<typeof IonButton>;

/**
 * Standardised secondary button
 */
const SecondaryButton = ({
  children,
  ariaText,
  type = "button",
  color = "secondary",
  size = "small",
  ...props
}: SecondaryButtonProps) => (
  <IonButton
    className="secondary-button"
    aria-label={ariaText}
    size={size}
    fill="outline"
    shape="round"
    color={color}
    type={type}
    {...props}
  >
    <div className="secondary-button__inner">{children}</div>
  </IonButton>
);

export default SecondaryButton;
