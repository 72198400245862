import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SecondaryButton from "components/atom/button-secondary/button-secondary";
import { CurrencyProvider } from "types/wallet";
import { useEffect, useState } from "react";
import { AmountInput } from "./amount-input";

const paymentAmountFormSchema = yup.object({
  amount: yup
    .number()
    .typeError(
      "Invalid payment amount. Please enter a number, without any special characters such as currency symbols.",
    )
    .moreThan(0, "Amount must be greater than 0")
    .required(),
});

export type AmountFormProps = {
  updateTotalCost: (newAmount?: number) => void;
  presetAmount?: number;
  providersList?: Array<CurrencyProvider>;
  clearProvidersList: () => void;
  providersRequestLoading: boolean;
  providersRequestError?: string;
  paymentSubmitting?: boolean;
};

export const AmountForm = ({
  presetAmount,
  paymentSubmitting,
  providersRequestLoading,
  providersList,
  providersRequestError,
  updateTotalCost,
  clearProvidersList,
}: AmountFormProps) => {
  const [allowProvidersRequest, setAllowProvidersRequest] = useState(false);

  const { control, setValue, handleSubmit } = useForm({
    defaultValues: {
      amount: presetAmount || 0,
    },
    resolver: yupResolver(paymentAmountFormSchema),
  });

  // Successfully populated the providers list, user can't repeat the request without changing the amount
  useEffect(() => {
    if (providersList?.length) setAllowProvidersRequest(false);
  }, [providersList, setAllowProvidersRequest]);

  // Error occured on getting the providers list, user can repeat the request
  useEffect(() => {
    if (providersRequestError) setAllowProvidersRequest(true);
  }, [providersRequestError, setAllowProvidersRequest]);

  // Preset amount is defined on selecting an individual invoice
  // Set the total cost from preset and request the payment providers
  useEffect(() => {
    if (presetAmount) {
      updateTotalCost(presetAmount);
      setValue("amount", presetAmount);
    }
  }, [presetAmount, updateTotalCost, setValue]);

  // Payment value has been changed but not submitted
  const handlePaymentAmountChange = () => {
    setAllowProvidersRequest(true);
    clearProvidersList();
  };

  // Payment value is set to state, send a request to payment provider options
  const handleSetPaymentAmount: SubmitHandler<{ amount: number }> = async (formValues) => {
    updateTotalCost(formValues.amount);
  };

  const nonUsdCurrencies = providersList?.find((e) => e.CurrencyCode !== "USD" && e.Amount);
  const nonUsdAmount = (amount: number, currency: string) => {
    const readableAmount = parseFloat(amount.toFixed(2)).toLocaleString("en-us");
    if (currency === "NGN") return `${String.fromCharCode(8358)}${readableAmount}`;
    return `${readableAmount} ${currency}`;
  };

  return (
    <form onSubmit={handleSubmit(handleSetPaymentAmount)}>
      <div className="payment-info__row">
        <div className="payment-info__col">
          <AmountInput
            control={control}
            name="amount"
            onChange={() => {
              handlePaymentAmountChange();
            }}
            disabled={paymentSubmitting || providersRequestLoading}
          />
        </div>
        {allowProvidersRequest && (
          <div className="payment-info__col">
            <SecondaryButton
              type="submit"
              disabled={paymentSubmitting || providersRequestLoading}
              data-testid="amount-form-confirm-button"
            >
              Confirm Amount
            </SecondaryButton>
          </div>
        )}
        {!!nonUsdCurrencies && (
          <div className="payment-info__col payment-info__col--exchange-rate">
            <span data-testid="amount-form-non-usd-amount">
              {nonUsdAmount(nonUsdCurrencies.Amount, nonUsdCurrencies.CurrencyCode)}
            </span>
          </div>
        )}
      </div>
      {!!nonUsdCurrencies && (
        <div data-testid="amount-form-exchange-rate" className="payment-info__row payment-info__row--exchange-rate">
          <span>{`1 USD = ${nonUsdCurrencies.ExchangeRate} ${nonUsdCurrencies.CurrencyCode}`}</span>
        </div>
      )}
    </form>
  );
};
