import { IonText, IonIcon } from "@ionic/react";

import { NxuComponentLoading } from "@nexford/nexford-ui-component-library";
import CardPanel from "components/atom/card-panel/card-panel";
import { alertCircleOutline } from "ionicons/icons";
import { CurrencyProvider } from "types/wallet";

type Props = {
  providersRequestLoading: boolean;
  providersList?: CurrencyProvider[];
};

export const PaymentAlert = ({ providersRequestLoading, providersList }: Props) => {
  if (providersList?.length) return null;

  return (
    <CardPanel className="payment-info__set-amount" centre testId="payment-info-set-amount">
      {providersRequestLoading ? (
        <NxuComponentLoading testId="payment-alert-loading" />
      ) : (
        <span data-testid="payment-alert-content">
          <IonIcon aria-label="Info" icon={alertCircleOutline} size="large" color="danger" />
          <IonText color="danger">
            <p>Set and confirm the amount to be paid to get your payment options</p>
          </IonText>
        </span>
      )}
    </CardPanel>
  );
};
