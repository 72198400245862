import { TuitionRequest } from "types/wallet";
import { WalletBalance } from "./wallet-balance";
import { WalletDebt } from "./wallet-debt";

type Props = {
  tuitionData: TuitionRequest;
};

export const PaymentBalance = ({ tuitionData }: Props) => {
  if (tuitionData.totalAmountDue <= 0) {
    return <WalletBalance balance={tuitionData.balance} nextBillDate={tuitionData.nextBillDate} />;
  }

  return <WalletDebt totalAmountDue={tuitionData.totalAmountDue} invoices={tuitionData.invoices} />;
};
