import React, { useMemo, useState } from "react";
import { isPlatform } from "@ionic/react";

import config from "config";
import { ANDROID_APP_URL, IOS_APP_URL } from "constants/external-routes";
import { isMobileOS } from "utils/detect-device";

import { NxuAlert, NxuContentLoading } from "@nexford/nexford-ui-component-library";
import CardPanel from "components/atom/card-panel";
import SecondaryButton from "components/atom/button-secondary";
import LearnerPathModal from "components/molecule/learner-path-modal";

import "./learner-path-list.scss";
import { LearningPathCourse } from "types/learning-path";
import { LearnerPathListContent } from "./learner-path/learner-path";
import { SeminarList } from "./seminar-list/seminar-list";

export interface LearnerPathListProps {
  programTitle?: string;
  specializations?: string[];
  list?: LearningPathCourse[];
  isLoading?: boolean;
  error?: Error | null;
}

/**
 * Display a list of the user's course enrollments
 */
const LearnerPathList = ({ programTitle, list, isLoading, error, specializations }: LearnerPathListProps) => {
  const [currentCourse, setCurrentCourse] = useState<LearningPathCourse | null>(null);
  const [isCourseModalOpen, setIsCourseModalOpen] = useState(false);

  const seminarsList = useMemo(() => list?.filter((item) => item.isSeminar), [list]);

  const closeCourseModal = () => {
    setIsCourseModalOpen(false);
    setCurrentCourse(null);
  };

  const openCourseModal = (course: LearningPathCourse) => {
    setCurrentCourse(course);
    setIsCourseModalOpen(true);
  };

  if (isLoading) {
    return <NxuContentLoading />;
  }

  return (
    <section className="learner-path">
      <div className="learner-path__title">
        <h2>{programTitle}</h2>
        {!!specializations?.length && (
          <p>
            {specializations.length > 1 ? "Specializations:" : "Specialization:"}
            {specializations.map((item) => (
              <span key={item}>{item}</span>
            ))}
          </p>
        )}
      </div>

      {error && (
        <NxuAlert
          message={`We're having difficulty getting your program summary: ${error.message}. Please refresh the page and if the problem persists,
          contact support for help.`}
        />
      )}
      <LearnerPathListContent list={list} onItemClick={openCourseModal} />

      <SeminarList list={seminarsList} />

      {!!list && (
        <CardPanel className="learner-path__canvas-block">
          <p>Want to see all of your course materials?</p>
          <SecondaryButton href={config.url.canvas}>Go to Canvas</SecondaryButton>
          {isMobileOS() && (
            <>
              <p>Or take Canvas with you on your phone</p>
              <SecondaryButton href={isPlatform("android") ? ANDROID_APP_URL : IOS_APP_URL}>
                Install Canvas
              </SecondaryButton>
            </>
          )}
        </CardPanel>
      )}
      <LearnerPathModal course={currentCourse} isOpen={isCourseModalOpen} closeModal={closeCourseModal} />
    </section>
  );
};
export default LearnerPathList;
